<template>
  <div id="depositFunds">
    <div class="content_box">
      <div class="inner_max">
        <div class="page-header">{{ $t('menu.depositFund') }}</div>
        <div class="deposit_channel">
          <div v-for="(payment, index) in depositList" :key="`payment_${index}`">
            <!-- CPS Deposit List -->
            <div
              class="channel"
              @click="cpsClick(payment)"
              :data-testid="convertDataTestId(payment.child[0].merchant_variable.description_title)"
              :key="'cps-' + index"
              v-if="payment.isCps && payment.child && payment.child.length > 0"
            >
              <div>
                <div v-if="payment.child[0].merchant_variable">
                  <div class="bottom">
                    <div class="logo">
                      <img
                        :src="paymentLogo(payment.payment_method, payment.child[0].actual_currency_code)"
                        alt="logo"
                      />
                    </div>
                    <div class="info_content" v-if="payment.child[0].merchant_variable.fee">
                      <div class="d-inline-block" v-if="payment.child[0].merchant_variable.fee">
                        <span class="up d-block">{{ payment.child[0].merchant_variable.fee }}</span>
                        <span class="down d-block">{{ $t('deposit.default.fee') }}</span>
                      </div>
                      <hr class="vertical_line" v-if="payment.child[0].merchant_variable.fee" />
                      <div class="d-inline-block">
                        <span class="up d-block">
                          {{ langTranslate('deposit.time.', payment.child[0].merchant_variable.processing_time) }}
                        </span>
                        <span class="down d-block" v-if="payment.child[0].merchant_variable.processing_time">{{
                          $t('deposit.default.instant')
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="text-white title">
                    {{
                      langTranslate('deposit.default.channels.', payment.child[0].merchant_variable.description_title)
                    }}
                  </div>
                  <div
                    class="preferred text-white position-absolute"
                    v-if="payment.child[0].merchant_variable.preferred"
                  >
                    <img src="@/assets/images/preferred.png" class="popular" alt />
                  </div>
                </div>
              </div>
            </div>
            <div
              :key="index"
              class="channel"
              @click="payment.clickFunction"
              v-else-if="payment.limitCountry ? limitPayment(payment) : true"
              :data-testid="payment.dataTestid"
            >
              <div class="bottom">
                <div class="logo"><img :src="payment.logo" alt /></div>
                <div class="info_content">
                  <div class="d-inline-block" v-if="payment.feeNumber">
                    <span class="up d-block">{{ payment.feeNumber }}</span>
                    <span class="down d-block">{{ $t('deposit.default.fee') }}</span>
                  </div>
                  <hr class="vertical_line" v-if="payment.feeNumber" />
                  <div class="d-inline-block">
                    <span class="up d-block">{{ payment.time }}</span>
                    <span class="down d-block" v-if="payment.instant">{{ $t('deposit.default.instant') }}</span>
                    <span
                      class="down d-block"
                      v-if="payment.businessDays"
                      v-html="$t('deposit.default.businessDays')"
                    ></span>
                    <span class="down d-block" v-if="payment.hour">{{ $t('deposit.default.hour') }}</span>
                    <span class="down d-block" v-if="payment.hours">{{ $t('deposit.default.hours') }}</span>
                  </div>
                </div>
              </div>
              <div class="text-white title">
                {{ $t(payment.title) }}
              </div>
              <div class="preferred text-white position-absolute" v-if="payment.preferred">
                <img src="@/assets/images/preferred.png" class="popular" alt />
              </div>
            </div>
          </div>
        </div>
        <div class="disclaimer p-4 border border-ebony-gray rounded">
          <ul
            class="text-stone-gray"
            v-html="$t('deposit.default.disclaimer', { platform: $platform.info.fullName })"
          ></ul>
        </div>

        <OptInDialog
          :dialogVisible.sync="dialogVisible"
          :dialogContent="dialogContent"
          @optInClick="goToDepositBonusPage"
          @hideOptInPopup="postCampaignOptInPopup"
        ></OptInDialog>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixins/deposit';
import { apiCampaignInfo, apiCampaignOptInPopup, apiQuery_mt_accounts } from '@/resource';
import OptInDialog from '@/components/deposit/OptInDialog';
import promotionMixin from '@/mixins/promotion';
import cpsMixin from '@/mixins/cpsDeposit';
import { apiGetCpsAvailableChannel } from '@/resource/cps';
import { cpsDepositListingIndex } from '@/constants/cpsDepositListingIndex';
import { countryIsoCode } from '@/constants/countryCode';
import { countryCodeEnum } from '@/constants/country';

export default {
  name: 'DepositFunds',
  mixins: [mixin, promotionMixin, cpsMixin],
  data() {
    return {
      dialogVisible: false,
      dialogContent: '',
      depositBonusLink: '/depositBonus',
      cpsDepositList: [],
      currencyList: []
    };
  },
  computed: {
    eligibleCampaigns() {
      return this.$store.state.promotion.eligibleCampaigns;
    },
    campaignDepositBonusID() {
      return this.eligibleCampaigns.find(c => c.campaignType === 'DEPOSIT_BONUS_2').id;
    },
    getCountryIsoCode() {
      return countryIsoCode.find(c => c.id === parseInt(this.$store.state.common.countryCode)).code;
    },
    depositList() {
      let list = [...this.cpsDepositList, ...this.$platform.getPayments(this.regulator, this)];

      if (this.countryCode === countryCodeEnum.KOREA) {
        list = this.cpsSort(list, 'T00600', list.length);
      }

      return list;
    }
  },
  components: { OptInDialog },
  async mounted() {
    // get trading account currencies
    this.fetchTradingAccount();

    if (
      this.eligibleCampaigns.length > 0 &&
      this.eligibleCampaigns.some(campaign => campaign.campaignType === 'DEPOSIT_BONUS_2')
    ) {
      const resp = await apiCampaignInfo(this.campaignDepositBonusID);
      const campaignInfoResp = resp.data;

      if (campaignInfoResp.code === 0) {
        let overBonus = campaignInfoResp.data.reachedMaximumBonus;
        let notInCampaign = !campaignInfoResp.data.in;

        if (!overBonus && notInCampaign) {
          if (this.eligibleCampaigns.some(campaign => campaign.name === 'Welcome5010')) {
            this.handleWelcome5010Popup(campaignInfoResp.data.hasDepositRecord, campaignInfoResp.data.showOptInPopup);
          } else {
            // this.showOptInNotification();
          }
        }
      }
    }
  },
  methods: {
    cpsSort(sortedDepositList, method, idx) {
      const idnVirtualAccountItem = sortedDepositList.filter(e => e.payment_method === method);
      const idnVirtualAccountIndex = sortedDepositList.findIndex(e => e.payment_method === method);
      if (idnVirtualAccountItem && idnVirtualAccountItem.length) {
        sortedDepositList.splice(idnVirtualAccountIndex, 1);
        sortedDepositList.splice(idx, 0, idnVirtualAccountItem[0]);
      }
      return sortedDepositList;
    },
    updateDialog(content) {
      this.dialogContent = content;
      this.dialogVisible = true;
    },
    getOptInPopupContent() {
      return {
        title: this.$t('promotion.depositBonus.titlePopup'),
        image: 'depositBonus/50_bonus_popup.png',
        checkbox: true,
        checkboxLabel: this.$t('promotion.depositBonus.checkboxLabelPopup'),
        info: this.$t('promotion.depositBonus.infoPopup', {
          tncLink: this.tncRedirect()
        })
      };
    },
    getOptInNotificationContent() {
      return this.$t('promotion.depositBonus.notification', {
        tncLink: this.tncRedirect(),
        depositBonusLink: this.depositBonusLink
      });
    },
    handleWelcome5010Popup(hasDepositRecord, optInPopupEnabled) {
      if (hasDepositRecord) {
        // this.showOptInNotification();
      } else if (optInPopupEnabled) {
        // this.showOptInPopup();
      }
    },
    showOptInNotification() {
      const h = this.$createElement;
      const content = this.getOptInNotificationContent();
      const message = h('div', { domProps: { innerHTML: content } });
      let self = this;

      this.$notify({
        duration: 5000,
        offset: 70,
        position: this.$store.state.common.lang !== 'ar' ? 'top-right' : 'top-left',
        message: message,
        onClick: function () {
          const notifyClickHandler = function (e) {
            if (e.target.tagName !== 'A') {
              self.goToDepositBonusPage();
              self.$notify.closeAll();
            }
            window.removeEventListener('click', notifyClickHandler);
          };
          window.addEventListener('click', notifyClickHandler);
        }
      });
    },
    goToDepositBonusPage() {
      this.$router.push(this.depositBonusLink);
    },
    showOptInPopup() {
      const content = this.getOptInPopupContent();
      this.updateDialog(content);
    },
    async postCampaignOptInPopup() {
      await apiCampaignOptInPopup(this.campaignDepositBonusID, false);
    },
    getCPSDepositChannels() {
      let payload = {
        country: this.getCountryIsoCode
      };

      apiGetCpsAvailableChannel(payload).then(response => {
        let channelData = JSON.parse(response.data.data);

        // assign cps deposit list
        this.cpsDepositList = this.filterCpsDepositListByRegulator(channelData.channels).map(e => {
          return { ...e, isCps: true };
        });
      });
    },
    // FilterCpsDepositListByRegulator's logic
    //
    // 1. Filter by regulator & production
    // 2. Group different account currency based on same payment method (Exclude Crypto)
    // 3. Group channels with same parent
    // 4. Sort channels' child with order
    // 5. Sort channels in order based on country
    filterCpsDepositListByRegulator(channels) {
      let finalCpsDepositList = [];
      let parentArray = [];
      let tradeAccountGroupArray = [];
      let channelList = [];
      let depositListOrder = [];
      let sortedDepositList = [];

      if (channels.length > 0) {
        // check for merchant variable regulator & filtering
        channels.map(m => {
          let regulatorArray = [];

          // parse merchant variable
          let merchantVariable = m.merchant_variable ? JSON.parse(m.merchant_variable) : '';
          m.merchant_variable = merchantVariable ? merchantVariable : '';

          // parse attach variable
          let attachVariables = m.attach_variable ? Object.values(JSON.parse(m.attach_variable)) : '';
          m.attach_variable = attachVariables ? attachVariables : '';

          if (merchantVariable?.regulator) {
            regulatorArray = merchantVariable.regulator.split(',');
          }

          // push to final cps deposit list if regulator is matched & production is true
          if (regulatorArray.includes(this.regulator) && merchantVariable.production) {
            // Exclude T00400 (Crypto) for merging trade_account
            if (m.payment_method == 'T00400') {
              tradeAccountGroupArray.push(m);
            } else {
              let paymentIndex = tradeAccountGroupArray.findIndex(function (p) {
                return p.payment_method == (merchantVariable.parent ? merchantVariable.parent : m.payment_method);
              });

              // create parent by payment method
              if (paymentIndex < 0) {
                tradeAccountGroupArray.push(m);
              }

              channelList.push(m);
            }
          }
        });

        // Start merging trade_account for the same payment method
        if (channelList.length > 0 && tradeAccountGroupArray.length > 0) {
          channelList.forEach((p, index) => {
            let paymentIndex = tradeAccountGroupArray.findIndex(function (pi) {
              return pi.payment_method == (p.merchant_variable.parent ? p.merchant_variable.parent : p.payment_method);
            });

            // only group when payment method matches
            if (paymentIndex >= 0) {
              // Create unique_currency under the child
              if (tradeAccountGroupArray[paymentIndex].actual_currency_code != p.actual_currency_code) {
                let actualCurrencyCodeObject = {};

                actualCurrencyCodeObject = {
                  actual_currency_code: p.actual_currency_code,
                  actual_currency_number: p.actual_currency_number,
                  currency_code: p.currency_code,
                  currency_number: p.currency_number,
                  regulator: p.merchant_variable?.regulator,
                  trade_account: p.merchant_variable?.trade_account
                };

                if (!tradeAccountGroupArray[paymentIndex].hasOwnProperty('unique_currency')) {
                  let uniqueCurrencyArray = [];

                  uniqueCurrencyArray = [
                    {
                      actual_currency_code: tradeAccountGroupArray[paymentIndex].actual_currency_code,
                      actual_currency_number: tradeAccountGroupArray[paymentIndex].actual_currency_number,
                      currency_code: tradeAccountGroupArray[paymentIndex].currency_code,
                      currency_number: tradeAccountGroupArray[paymentIndex].currency_number,
                      regulator: tradeAccountGroupArray[paymentIndex].merchant_variable?.regulator,
                      trade_account: tradeAccountGroupArray[paymentIndex].merchant_variable?.trade_account
                    }
                  ];

                  tradeAccountGroupArray[paymentIndex].unique_currency = uniqueCurrencyArray;
                  tradeAccountGroupArray[paymentIndex].unique_currency.push(actualCurrencyCodeObject);
                } else {
                  tradeAccountGroupArray[paymentIndex].unique_currency.push(actualCurrencyCodeObject);
                }
              }

              // Merge trade account & limit
              if (
                tradeAccountGroupArray[paymentIndex].merchant_variable.trade_account !=
                p.merchant_variable?.trade_account
              ) {
                tradeAccountGroupArray[paymentIndex].merchant_variable.trade_account =
                  tradeAccountGroupArray[paymentIndex].merchant_variable?.trade_account +
                  ',' +
                  p.merchant_variable?.trade_account;

                tradeAccountGroupArray[paymentIndex].merchant_variable.limit = tradeAccountGroupArray[
                  paymentIndex
                ].merchant_variable.limit.concat(p.merchant_variable.limit);
              }
            }
          });
        }

        // Start create parent & child grouping
        if (tradeAccountGroupArray.length > 0) {
          tradeAccountGroupArray.map(m => {
            let paymentIndex = parentArray.findIndex(function (p) {
              return p.payment_method == (m.merchant_variable?.parent ? m.merchant_variable?.parent : m.payment_method);
            });

            // Create parent by payment method
            if (paymentIndex < 0) {
              let newPaymentMethod = m.merchant_variable?.parent ? m.merchant_variable?.parent : m.payment_method;

              let paymentMethod = {
                payment_method: newPaymentMethod,
                description_title: m.description_title,
                processing_time: m.processing_time,
                fee: m.fee,
                child: []
              };
              parentArray.push(paymentMethod);
            }

            finalCpsDepositList.push(m);
          });
        }

        // Start grouping and create child
        if (parentArray.length > 0) {
          finalCpsDepositList.forEach(p => {
            let paymentIndex = parentArray.findIndex(function (pi) {
              return pi.payment_method == (p.merchant_variable.parent ? p.merchant_variable.parent : p.payment_method);
            });

            if (paymentIndex != -1) {
              parentArray[paymentIndex].child.push(p);
            }
          });

          // Sort by order
          parentArray.forEach(pa => {
            let sortedArray = pa.child.sort((a, b) => a.merchant_variable?.order - b.merchant_variable?.order);
            pa.child = sortedArray;
          });

          // Sort based on deposit listing index
          cpsDepositListingIndex.forEach(de => {
            if (de.id == this.countryCode) {
              depositListOrder = de.order;
            }
          });

          if (depositListOrder.length == 0) {
            cpsDepositListingIndex.forEach(de => {
              if (de.id == 'global') {
                depositListOrder = de.order;
              }
            });
          }

          depositListOrder.forEach(de => {
            parentArray.forEach(pa => {
              if (de == pa.payment_method) {
                sortedDepositList.push(pa);
              }
            });
          });

          let remainingPayments = parentArray.filter(v => !depositListOrder.includes(v.payment_method));
          sortedDepositList = sortedDepositList.concat(remainingPayments);
        }
      }

      return sortedDepositList;
    },
    limitPayment(payment) {
      if (payment.hasOwnProperty('checkIdPass') && !payment.checkIdPass) return false;
      if (payment.onlyThisCountry && this.countryCode === payment.onlyThisCountry) return true;
      if (payment.restrictThisCountry && this.countryCode !== payment.restrictThisCountry) return true;
      if (payment.showPayment) return true;
      return false;
    },
    limitCpsPayment(payment) {
      if (payment.country && this.getCountryIsoCode === payment.country) return true;
      return false;
    },
    cpsClick(payment) {
      if (payment && payment.child.length > 0) {
        this.$store.dispatch('cps/actionSetPayment', payment);
        this.$router.push({ name: 'cps', params: { name: payment.child[0]?.merchant_variable?.description_title } });
      }
    },
    fetchTradingAccount() {
      apiQuery_mt_accounts({ supportedCurrencies: 'all' }).then(resp => {
        if (resp.data.code == 0) {
          // push all currencies to array
          for (var i = 0; i < resp.data.data.length; i++) {
            this.currencyList.push(resp.data.data[i].currency);
          }
          this.currencyList = [...new Set(this.currencyList)];

          // get cps deposit channels list
          this.getCPSDepositChannels();
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/depositFunds.scss';
</style>
